import { Box, Link, Typography } from '@mui/material';
import { FC, useMemo } from 'react';

import {
  OSTANA_MAIN_SITE,
  PRIVACY_POLICY_URL,
  TERMS_AND_CONDITION_URL,
} from '@/utils/constants';

const links = [
  {
    title: 'Privacy Policy',
    href: PRIVACY_POLICY_URL,
  },
  {
    title: 'Terms of Use',
    href: TERMS_AND_CONDITION_URL,
  },
];

interface Props {
  authScreen?: boolean;
}

export const Footer: FC<Props> = ({ authScreen }) => {
  const filteredLinks = useMemo(
    () =>
      authScreen
        ? [
            {
              title: 'Visit Ostana.io',
              href: OSTANA_MAIN_SITE,
            },
            ...links,
          ]
        : links,
    [authScreen],
  );

  return (
    <Box
      component="footer"
      sx={(theme) => ({
        display: 'flex',
        justifyContent: 'center',
        height: '72px',
        background: theme.palette.background.cardExtraLayer,
        width: '100%',
      })}
    >
      <Box
        sx={(theme) => ({
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          p: '0 24px 0 40px',
          gridColumn: '1 / 3',
          width: '100%',
          [theme.breakpoints.up('xl')]: {
            width: '1920px',
          },
        })}
      >
        <Box sx={{ display: 'flex', columnGap: '32px' }}>
          {filteredLinks.map((x) => (
            <Link
              href={x.href}
              key={`${x.href}-${x.title}`}
              target="_blank"
              rel="noreferrer"
              sx={(theme) => ({
                color: theme.palette.text.caption,

                ':hover': {
                  color: theme.palette.primary.main,
                },
              })}
              variant="p1"
            >
              {/* <Typography variant="p1" color="text.caption"> */}
              {x.title}
              {/* </Typography> */}
            </Link>
          ))}
        </Box>
        <Box>
          <Typography variant="p1" color="text.caption">
            © 2024 Ostana. All rights reserved.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};
