import { CountryCode } from 'libphonenumber-js';

import { SelectOption } from '@/components/common/form/Select/CommonSelect';

export const allowedCountries: CountryCode[] = ['CA', 'US'];

export const genderOptions: SelectOption[] = [
  {
    label: 'Male',
    value: 'MALE',
  },
  {
    label: 'Female',
    value: 'FEMALE',
  },
  {
    label: 'Others',
    value: 'OTHER',
  },
];


export const TERMS_AND_CONDITION_URL = 'https://ostana.io/terms-conditions/';
export const PRIVACY_POLICY_URL = 'https://ostana.io/privacy-policy/';
export const OSTANA_MAIN_SITE = 'https://ostana.io/';
export const PEXELS_URL = 'https://images.pexels.com/';
export const DEFAULT_BUSINESS_COVER = '/images/businessInfoCoverDefault.png';
export const REDIRECT_URL_KEY = 'redirectUrl';
export const REFERRAL_CODE = 'referral-code';
export const AUTO_JOIN = 'auto-join';
