import { signOut } from '@aws-amplify/auth';

import { USER_TYPE } from '@/utils/types'

const STORAGE_KEY = 'login-type';
export const saveLogin = (type: USER_TYPE) =>
  localStorage.setItem(STORAGE_KEY, type);

export const getSavedLogin = () => localStorage.getItem(STORAGE_KEY);

export const clearSavedLogin = () => localStorage.removeItem(STORAGE_KEY);

export const logOut = async () => {
  await signOut();
  clearSavedLogin();
};
